// 文档链接 https://1x.antdv.com/docs/vue/introduce-cn/

import Vue from 'vue'

import {
  Button,
  Dropdown,
  Icon,
  Input,
  Modal,
  Menu,
  message,
  Popover,
  Result,
  Select,
  Spin,
  Tooltip,
  Carousel,
  Tabs,
  Drawer
} from 'ant-design-vue'

Vue.use(Button)
Vue.use(Dropdown)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Modal)
Vue.use(Menu)
Vue.use(Popover)
Vue.use(Result)
Vue.use(Select)
Vue.use(Spin)
Vue.use(Tooltip)
Vue.use(Carousel)
Vue.use(Tabs)
Vue.use(Drawer)

message.config({
  top: '40px',
  maxCount: 2
})

Vue.prototype.$message = message
export { Vue }
