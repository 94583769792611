import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)
NProgress.configure({ showSpinner: false })

const appDomain = process.env.VUE_APP_APP_URL

const routes = [
  {
    path: '/',
    name: 'DID',
    meta: {
      domain: appDomain
    },
    component: () => import('@/views/Registration.vue'),
    children: [
      {
        path: '/dapp',
        name: 'dapp',
        meta: {
          domain: appDomain
        },
        // 路由独享钩子
        beforeEnter: (to, from, next) => {
          if (to.query.r) {
            Vue.prototype.$agent = to.query.r
          }
          console.log('query:', to.query)
          next()
        }
      },
      {
        path: '/names',
        name: 'names',
        meta: {
          domain: appDomain
        }
      }
    ]
  },

  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

function scrollBehavior (to, from, savedPosition) {
  return {
    x: 0,
    y: 0
  }
}

// 全局路由守卫钩子
router.beforeEach((to, from, next) => {
  // 开始顶部进度条的动画
  NProgress.start()
  Vue.prototype.$fromUrl = from.path
  console.log('Route from:', from, 'Route to:', to)
  next()
})

router.afterEach(() => {
  // 结束顶部进度条的动画
  NProgress.done()
})

export default router
