import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 自定义语言文件
import zhCN from './locales/zh-CN'
import enUS from './locales/en-US'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en-US',
  messages: {
    'zh-CN': zhCN,
    'en-US': enUS
  }
})

export {
  VueI18n
}
