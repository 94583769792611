import axios from 'axios'

const install = (Vue, vm) => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE, // 跨域代理时无需设置
    timeout: 15000,
    transitional: {
      clarifyTimeoutError: true
    },
    headers: { 'Content-Type': 'application/json' }
  })

  // 请求拦截器
  instance.interceptors.request.use(
    (config) => {
      // ...to do sth
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // 响应拦截器
  instance.interceptors.response.use(
    (response) => {
      // ...to do sth
      return response.data
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  Vue.prototype.$axios = instance
}

export default {
  install
}
