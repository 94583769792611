import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  address: '',
  chainId: '',
  login: false,
  reverseFullName: ''
}

const mutations = {

  SET_LOGIN (state, login) {
    state.login = login
  },

  SET_ADDRESS (state, address) {
    state.address = address
  },

  SET_CHAINID (state, chainId) {
    state.chainId = chainId
  },

  SET_REVERSE_FULL_NAME (state, reverseFullName) {
    state.reverseFullName = reverseFullName
  }
}

const actions = {

  setLogin ({ commit }, login) {
    commit('SET_LOGIN', login)
  },

  setAddress ({ commit }, address) {
    commit('SET_ADDRESS', address)
  },

  setChainId ({ commit }, chainId) {
    commit('SET_CHAINID', chainId)
  },

  setReverseFullName ({ commit }, reverseFullName) {
    commit('SET_REVERSE_FULL_NAME', reverseFullName)
  }
}

const getters = {
  login: state => state.login,
  address: state => state.address,
  chainId: state => state.chainId,
  reverseFullName: state => state.reverseFullName
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
