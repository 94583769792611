import { providers, Contract } from 'ethers'

import { TreeDBABI } from '@/constants/TreeDB.json'
import { RegistrarABI } from '@/constants/Registrar.json'
import { EditorABI } from '@/constants/Editor.json'
import { ResolverABI } from '@/constants/Resolver.json'
import { MultiCallABI } from '@/constants/MultiCall.json'
import { UsdtABI } from '@/constants/Usdt.json'

const TreeDBAddr = process.env.VUE_APP_TREEDB_ADDR
const RegistrarAddr = process.env.VUE_APP_REGISTRAR_ADDR
const EditorAddr = process.env.VUE_APP_EDITOR_ADDR
const ResolverAddr = process.env.VUE_APP_RESOLVER_ADDR
const MultiCallAddr = process.env.VUE_APP_MULTICALL_ADDR
const UsdtAddr = process.env.VUE_APP_BSC_USDT_ADDR

const bscRpcUrl = process.env.VUE_APP_BSC_RPC_URL

const createContract = (provider, address, abi) => {
  let signer, ethersProvider

  if (provider) {
    signer = provider
    if (window.walletConnectWeb3Provider) {
      // WalletConnect
      ethersProvider = new providers.Web3Provider(
        window.walletConnectWeb3Provider
      )
    } else {
      // Metamask
      ethersProvider = new providers.Web3Provider(window.ethereum)
    }
  } else {
    // BSC mainnet
    ethersProvider = new providers.JsonRpcProvider(bscRpcUrl)
  }

  const contract = new Contract(
    address,
    abi,
    signer ? ethersProvider.getSigner() : ethersProvider
  )
  return contract
}

const contractInterface = {
  treeDB: (provider) => createContract(provider, TreeDBAddr, TreeDBABI),
  registrar: (provider) => createContract(provider, RegistrarAddr, RegistrarABI),
  editor: (provider) => createContract(provider, EditorAddr, EditorABI),
  resolver: (provider) => createContract(provider, ResolverAddr, ResolverABI),
  multiCall: (provider) => createContract(provider, MultiCallAddr, MultiCallABI),
  usdt: (provider) => createContract(provider, UsdtAddr, UsdtABI)
}

export default contractInterface
